.mission-visible {
    display: flex;
    flex-direction: column;

    opacity: 1;
    filter: blur(0);
    transform:translateY(0);
    align-items: center;
    transition: all 1s;

}

.mission-hidden {
    display: flex;
    flex-direction: column;

    opacity: 0;
    transform:translateY(50%);
    transition: all 1s;
    filter: blur(5px);
    align-items: center;

}

.animatePicture {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
 
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}