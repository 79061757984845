html {
    margin-right: 0px;
}

body {
  font-family: Roboto, sans-serif;
}
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  

}

.HwElement {
  background-color: #00000000 !important;
}

.topic {
  display: flex;
  height:30px;
}

.topic:hover .topicCloseButton {
  display:block;
}

.topicCloseButton {
  margin-top:1.5px;
  display: none
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.speakerText {
  color: ""
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7b7b7b;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f6f6f682;
  border-radius: 10px;
  /* padding: 1rem; */
  /* translate: 10px 10px; */
  box-shadow: 0 0 5px #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
      /* background: #b30000; */
      background: #f6f6f682;
      border-radius: 10px;
      /* padding: 1rem; */
      /* translate: 10px 10px; */
      box-shadow: 0 0 5px #000000;
}

.gridCard {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    padding:0px;
    min-width: 0px;
}



.gridInset {
  position: relative;
padding: 15px;
margin: 15px;
flex: 1 1 auto;
border-radius: 15px;
box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 4px 0px inset;
background: #0000001f; overflow:auto; 
}

.libraryItem {
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ffffff68;
  z-index: 6;
  display:flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 2px 0px;

}

.libraryItemPopup {
  position:absolute;
   width: 300px; 
   height:auto; 
   background:#f4f4f4ea;
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 4px 0px;
   padding:10px;
   z-index: 10;
}

.libraryItem:hover {
  background-color: #019eff76
}

.gridNoInset {
  position: relative;
padding: 15px;
flex: 1 1 auto;
border-radius: 15px;
overflow:auto;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: rgba(131, 255, 241, 0.589);
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius:15px;

}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
  color:white;
  margin:5px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgb(0, 162, 255);
  border-bottom: 2px solid rgb(0, 162, 255);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: grey;
  border-radius:15px;
  
}

.window-header {
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.logo-container {
  overflow: hidden;
}

.app-name {
  color: black;
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 8px;
}

.actions-container {
  display: flex;
  padding-top: 3px;
}

.icon {
  color: rgb(255, 255, 255);
  font-size: 20px;
  padding-top: 5px;
}

.window-button-style {
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.paneControls {
  display:flex;
  gap:5px;
  margin-top: 14px;
  margin-right: 17px;
}

.controlsIcon {
   z-index: 4;
   color: #f4f4f4;
   width: 25px;
   height: 25px;
  
}

.window-controls-icon {
  pointer-events: none;
  user-select: none;
  width: 20px;
  height: 20px;
  transition: fill 150ms ease;
}

.settings-window:hover {
  background-color: orange;
}

.close-window:hover {
  background-color: #e6004c;
}

.settings-window:focus {
  outline: none;
}

.close-window:focus {
  outline: none;
}
