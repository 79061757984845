.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 200px;
}

@media screen and (max-width: 1400px ) {
  .App{
    gap: 100px
  }
}

@media screen and (max-width: 1100px ) {
  .App{
    gap: 0px
  }
}

html, body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
}

.AppMobile {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.backdrop-image {
  align-self: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -100;
}

.toolbar-button {
  border: none;
  text-transform: none !important;
  background-color: #00000000 !important;
  color: #f4f4f4;
  text-align: center;
  font-size: 16px;
  scale: 1;
  font-weight: 100;
  text-decoration: none;
}

.toolbar-button-mobile {
  font-weight: 400;
  margin:10px;
  padding-left: 6px;
  text-decoration: none;
  color:white;
  padding-top: 5px;
}

.toolbar-button:hover {
  color: #5795ff;
  scale: 1.1;
  transition: all 0.3s
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}