.about{
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
   
    /* background-color: rgb(61, 61, 61); */
    /*height: 70vh;*/
}

p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.about_mission{
    width: 99%;
    font-size: 50px;
    font-weight: 200;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 2px; /* Increase letter spacing */
    line-height: 1.6; /* Increase line height for better readability */
    /*animation: slideIn 2s ease-in-out;  Simple slide-in animation */

    opacity: 1;
    filter: blur(0);
    transform:translateX(0);
    transition: all 1s;
}

.about_mission_hidden{
    width: 99%;
    font-size: 50px;
    font-weight: 200;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 2px; /* Increase letter spacing */
    line-height: 1.6;

    opacity: 0;
    transform:translateX(-5%);
    transition: all 1s;
    filter: blur(5px);
}

/* Add keyframes for animation */
@keyframes slideIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.about_container{
    /* background-color: aquamarine; */
    width: 100%;
    height: 50%;
    margin-top: 90px !important;
    display: flex;
    justify-content: center;
    gap:70px;
    flex-wrap: wrap;
    row-gap: 120px;
}

.aboutCardGrid{
    position: relative;
    display: flex;
    flex-direction: column;
    height: max-content;
    align-items: center;

    opacity: 1;
    filter: blur(0);
    transform:translateX(0);
    align-items: center;
    transition: all 1s;
}

.aboutCardGrid_hidden{
    position: relative;
    display: flex;
    flex-direction: column;
    height: max-content;
    align-items: center;

    opacity: 0.5;
    transform:translateX(5%);
    transition: all 1s;
    filter: blur(5px);
    align-items: center;
}

.about_card2{
    width: 263px;
    height: 353px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #252525;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 4px 4px 0px;
    padding-top: 60px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.about_card2_mobile{
    width: 263px;
    height: 353px;
    flex-shrink: 0;
    border-radius: 20px;
    background: #252525;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(0 0 0 / 35%) 0px 4px 4px 0px;
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-top:30px
}

.about_container_card_photo2{
    position: absolute;
    bottom: 380px; 
    width: 120px;
    height: 120px;
    background-color: black;
    border-radius: 50%;
    z-index: 2; /* Ensure the circle is above the card */
    box-shadow: rgba(105, 105, 105, 0.35) 0px 5px 15px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.about_container_card_photo2_mobile{
    position: absolute;
    bottom: 400px; 
    width: 120px;
    height: 120px;
    background-color: black;
    border-radius: 50%;
    z-index: 2; /* Ensure the circle is above the card */
    box-shadow: rgba(105, 105, 105, 0.35) 0px 5px 15px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.about_container_card_photo2_image{
    /* mix-blend-mode:d; */
}

.about_container_card{
    background-color: #252525;
    width: 250px;
    border-radius: 20px;
    transition: transform 0.3s ease-in-out;
    box-shadow: rgba(105, 105, 105, 0.35) 0px 5px 15px;
}

.about_container_card:hover {
    transform: scale(1.05);
}

.about_container_card_photo2:hover{
    transform: scale(1.15);
}

.about_container_card_photo{
    width: 100%;
    height: 85%;
    /* background-color: aquamarine; */
    border-radius: 20px 20px 0 0;
}

.about_container_card_desc{
    /* background-color: antiquewhite; */
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

}



@media screen and (max-width: 1100px) {
    .about {
        margin-top:100px
    }

    .about_container{
        /* background-color: aquamarine; */
        width: 80%;
        height: 45%;
        margin-top: 70px;
        display: flex;
        justify-content: space-around;
        

    }

    .about_container_card{
        background-color: #252525;
        width: 200px;
        border-radius: 20px;
        transition: transform 0.3s ease-in-out;
    }
  }

  @media screen and (max-width: 750px) {
    .about_container{
        /* background-color: aquamarine; */
        width: 100%;
        height: max-content;
        margin-top: 0px !important;
        display: flex;
        flex-direction: column;

    }

    .aboutCardGrid{
        margin-top: 90px;
  
    }

    .about_card2{
        width: 263px;
        height: 389px;
    }

    .about_container_card{
        background-color: #252525;
        width: 150px;
        border-radius: 20px;
        transition: transform 0.3s ease-in-out;
    }

    .about_mission{
        width: 100%;
        letter-spacing: 1px; /* Increase letter spacing */
        line-height: 1.6; /* Increase line height for better readability */
       /*  animation: slideIn 2s ease-in-out; Simple slide-in animation */
    }
  }
  